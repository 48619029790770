import rentData from './rent_data.json';
class Flat {
    constructor(name, numRooms, isWbs = false, isAccessible = false, wgRoomList = null, combineRooms = null) {
        this.name = name;
        this.numRooms = numRooms;
        this.sizePrivate = 0;
        this.isWbs = isWbs;
        this.sizeShared = 0;
        // Normal flats have as many people as rooms
        // WGs and cluster flats have one more room
        if (!wgRoomList) {
            this.wgRoomList = [];
            this.numPeople = numRooms;
            var idList = combineRooms ? combineRooms : [name];
            this.minRent = 0;
            this.wbsRent = 0;
            this.freeRent = 0;

            for (var i = 0; i < idList.length; i++) {
                this.minRent += rentData[idList[i]].min_rent;
                this.wbsRent += rentData[idList[i]].wbs_rent;
                this.freeRent += rentData[idList[i]].free_rent;
                this.sizePrivate += rentData[idList[i]].size_private;
            }
        } else {
            this.wgRoomList = wgRoomList;
            this.sizeShared = this.sizePrivate;
            for (var j = 0; j < this.wgRoomList.length; j++) {
                this.sizeShared -= this.wgRoomList[j].size;
            }
            this.numPeople = numRooms - 1;
        }

    }

    // Returns a string representation of the flat
    print() {
        return this.name + " (" + this.numRooms + " Zimmer)";
    }

    // Calculates the share of the internal shared area the party has to pay for
    // This is only applicable for WGs and clusters, i.e. when a room is selected.
    calcInternalAreaShare(room = null) {
        return room ? this.sizeShared / this.numPeople * room.numPeople : 0;
    }

    // Calculates the total area the party has to pay for
    // Includes the private area and if applicable share of 
    // the internal common area of WGs and cluster flats
    calcTotalPayedArea(room = null) {
        if (!room) {
            return this.sizePrivate;
        } else {
            return room.size + this.calcInternalAreaShare(room);
        }
    }
}

class Room {
    constructor(name, numPeople = 1, id = null) {
        this.name = name;
        this.numPeople = numPeople;

        var key = id ? id : name;
        this.sizePrivate = rentData[key].size_private;
        this.sizeShared = rentData[key].size_shared;
        this.minRent = rentData[key].min_rent;
        this.freeRent = rentData[key].free_rent;
        this.wbsRent = rentData[key].wbs_rent;
    }

    print() {
        return this.name;
    }
}

export const flatData = {
    "north": {
        0: [
            new Flat("W 0.1 (WG)", 4, false, false, [
                new Room("0.1.02"), new Room("0.1.03"), new Room("0.1.04")
            ]),
            new Flat("0.2", 2, true),
            new Flat("0.3", 3, true)
        ],
        1: [
            new Flat("1.1", 4, true),
            new Flat("1.2", 1, true),
            new Flat("W 1.3 (WG)", 4, true, false, [
                new Room("1.3.02"), new Room("1.3.03"), new Room("1.3.04")
            ])
        ],
        2: [
            new Flat("2.1", 4, true),
            new Flat("2.2", 2, true),
            new Flat("W 2.3 (Cluster)", 5, true, true, [
                new Room("1 Person", 1, "2.3.02"), new Room("2 Personen", 2, "2.3.05"), new Room("1 Person (rollstuhlgerecht)", 1, "2.3.09")
            ]),
            new Flat("2.4", 2, true, true)
        ],
        3: [
            new Flat("3.1", 4),
            new Flat("W 3.2 (Cluster)", 5, true, true, [
                new Room("1 Person", 1, "3.2.02"), new Room("2 Personen", 2, "3.2.05"), new Room("1 Person (rollstuhlgerecht)", 1, "3.2.09")
            ]),
            new Flat("3.3", 3, true, false, null, ["3.3.02", "3.3.03", "3.3.04"])
        ]
    },
    "south": {
        0: [
            new Flat("0.4", 2, true),
            new Flat("0.5", 4, true),
            new Flat("0.6", 5, true),
        ],
        1: [
            new Flat("1.4", 2),
            new Flat("W 1.5 (WG)", 5, true, false,
                [new Room("1.5.02"), new Room("1.5.03"), new Room("1.5.05"), new Room("1.5.06")]
            ),
            new Flat("1.6.2", 1),
            new Flat("1.6.1", 5),
        ],
        2: [
            new Flat("2.5", 2, true),
            new Flat("2.6", 4, true),
            new Flat("2.7", 5)
        ]
    }
}
const addedCost = 1.5;
export const minRent = 9.45 + addedCost;
export const maxRent = 13.06 + addedCost;

export const minRentFactor = 0.85;